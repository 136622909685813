<header class="sm-header">
  <button
    class="refresh sm-button--img"
    (click)="refresh()"
    aria-label="Refresh standings"
    data-cy="refresh-div-standings">
    <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path fill="currentColor" d="M15.38,7.07 L14.46,1.46 L12.56,3.39 C11.45,2.57 10.12,2.12 8.72,2.12 C5.12,2.12 2.2,5.09 2.2,8.75 C2.2,12.4 5.12,15.38 8.72,15.38 C11.32,15.38 13.66,13.81 14.7,11.4 C14.83,11.09 14.7,10.74 14.4,10.6 C14.1,10.47 13.75,10.61 13.62,10.92 C12.77,12.89 10.84,14.17 8.72,14.17 C5.78,14.17 3.38,11.74 3.38,8.75 C3.38,5.75 5.78,3.32 8.72,3.32 C9.8,3.32 10.83,3.65 11.71,4.25 L9.86,6.13 L15.38,7.07 Z" />
    </svg>
    <span role="tooltip">Refresh standings</span>
  </button>
  <div [attr.data-cy]="division.name + '-division-header'">
    <span class="sticky-header__primary">{{ division.name }}</span>
    <span class="sticky-header__text">Division</span>
  </div>
</header>
<div *ngIf="!showTable" class="table empty-state" data-cy="no-stats-available">
  No standings available
</div>
<div
  class="standings"
  [class.scrollable]="scrollable"
  [class.scrollable-left]="scrollableLeft"
  [class.scrollable-right]="scrollableRight"
  #statTableWrapper
  *ngIf="showTable"
>
  <table class="header-table" [class.loading]="loading" #headerTable>
    <thead>
      <tr>
        <th class="shadow-left"></th>
        <th class="rank"></th>
        <th class="team-name">Teams</th>
        <th
          class="stat"
          *ngFor="let df of division.standings_preferences.display_fields"
          data-cy="division-stat-header"
        >
          <sm-abbr
            tooltip="{{ division.season?.standingsElements[df]?.description }}"
            data-cy="division-stat-abbrev"
            >{{ division.season?.standingsElements[df]?.abbrev }}</sm-abbr
          >
        </th>
        <th class="shadow-right"></th>
      </tr>
    </thead>
  </table>
  <table class="stat-table" [class.loading]="loading" #statTable>
    <tbody data-cy="division-standings-table">
      <!-- TODO: empty state for no teams -->
      <tr
        *ngFor="let tr of division.standings.teamRecords"
        data-cy="team-record"
      >
        <td class="shadow-left"></td>
        <td class="rank" data-cy="team-rank">
          {{ tr.team_rank }}
        </td>
        <td class="team-name" data-cy="team-name-standings">
          <div class="team-logo-name">
            <se-fe-avatar class="team-logo-header" seFeDataType="team"
              [seFeDataSport]="tr.team.season.sport_key"
              [seFeDataImgUrl]="tr.team.logo?.image_urls.extra_large"
              seFeDataSize="300"
              data-cy="team-standings-logo">
            </se-fe-avatar>
            <sm-nav-link
              class="link"
              url=".{{ teamUrl }}/{{ tr.team.id }}"
              [queryParams]="{ tab: 'standings' }"
              [label]="tr.team_name"
              tag="team-link-standings">
            </sm-nav-link>
          </div>
        </td>
        <td
          class="stat"
          *ngFor="let df of division.standings_preferences.display_fields"
          [class.sorted]="df === division.standings_preferences.order_by[0]"
          data-cy="stat-number"
        >
        {{
          df === "pct"
            ? (tr.values[df] || 0 | number : "1.3-3")
            : df === "div"
              ? tr.values[df]
              : (tr.values[df] || 0 | number)
        }}
        </td>
        <td class="shadow-right"></td>
      </tr>
    </tbody>
  </table>
</div>
